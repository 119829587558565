<template>
  <div>
    <h3>Оплата программы</h3>
    <br />
    <v-divider></v-divider> <br />
    <v-row>
      <v-col sm="4">
        <v-skeleton-loader
          v-if="flgLoad"
          type=" article, actions"
        ></v-skeleton-loader>
        <v-card outlined rounded v-if="!flgLoad">
          <v-card-text>
            Ваш тарифный план:
            <b
              >{{ info.tp_name }}
              <span v-if="info.stataddlimit > 0">
                + {{ info.stataddlimit }}</span
              ></b
            >
            <br />
            Стоимость одного дня:
            <b>
              {{ info.cost }}+ {{ info.stataddlimit * info.cost_worker }} =
              {{
                parseFloat(info.cost) + info.stataddlimit * info.cost_worker
              }} </b
            ><br />
            Стоимость одного месяца:
            <b>
              ~ {{ info.cost * 30 }}+
              {{ info.stataddlimit * info.cost_worker * 30 }} =
              {{
                parseFloat(info.cost) * 30 +
                info.stataddlimit * info.cost_worker * 30
              }}
            </b>
            <v-btn block small color="info" @click="changeTp">
              Изменить тариф
            </v-btn>
            <span v-if="info.tariff > 1">
              Оплачено до:<b
                >{{ info.dte_limit }} ( {{ info.days_left }} дней)</b
              >
            </span>
            <v-simple-table dense>
              <tbody>
                <tr v-if="info.tariff > 1">
                  <td>Основной счет</td>
                  <td>{{ info.cash }}</td>
                </tr>
                <tr v-if="info.tariff > 1 && info.bonus > 0">
                  <td>Бонусы</td>
                  <td>{{ info.bonus }}</td>
                </tr>
                <tr v-if="info.tariff > 1 && info.bonus_day > 0">
                  <td>Беспл. дней</td>
                  <td>{{ info.bonus_day }}</td>
                </tr>
                <tr v-if="info.tariff > 1 && info.sms > 0">
                  <td>SMS</td>
                  <td>{{ info.sms }}</td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-btn block small color="success" @click="dialog = true">
              Внести оплату
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="4" style="display: none">
        <v-card outlined rounded>
          <v-card-text>
            Ваш промо код: <b>promo{{ info.org }}</b>
            Приглашенные мастерские
            <table
              class="
                panel-body
                table table-striped table-bordered table-condensed
              "
            >
              <colgroup>
                <col width="*" />
                <col width="50px" />
                <col width="50px" />
              </colgroup>
              <thead>
                <tr>
                  <td>Название</td>
                  <td>
                    <span title="Дата последнего платежа">Посл.дата</span>
                  </td>
                  <td><span title="Полученная сумма">Сумма</span></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ref, idx) in referrer" v-bind:key="idx">
                  <td>{ref.name}</td>
                  <td>{ref.dte}</td>
                  <td class="td-right">{ref.smm}</td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" scrollable max-width="450px">
      <v-card>
        <v-card-title> Оплата программы </v-card-title>
        <v-card-text>
          Ваш тарифный план: <b>{{ info.tp_name }}</b> <br />
          <v-select
            hide-details
            v-bind:items="period"
            v-model="selperiod"
            return-object
            label="Период оплаты"
            item-text="name"
            item-value="value"
            @change="basesmm = selperiod.cost"
          ></v-select>
          <v-row>
            <v-col>
              <v-text-field
                label="Основной счет"
                v-model="basesmm"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="SMS" v-model="sms" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-select
            hide-details
            v-bind:items="paytpe"
            v-model="selpaytpe"
            return-object
            label="Способ оплаты"
            item-text="name"
            item-value="value"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn outlined @click="dialog = false">Закрыть</v-btn>&nbsp;
          <v-btn color="primary" @click="save">Оплатить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTp" scrollable max-width="450px">
      <v-card>
        <v-card-title> Изменить тариф </v-card-title>
        <v-card-text>
          Текущий тариф: <b>{{ info.tp_name }}</b> <br />
          <v-select
            hide-details
            v-bind:items="listtp"
            v-model="seltp"
            return-object
            label="Новый тариф"
            item-text="name"
            item-value="value"
          ></v-select>
          <v-row>
            <v-col>
              <v-text-field
                label="Добавить Мастеров/адресов"
                v-model="limittp"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col>
              Стоимость в день: {{ calcTpDay }} <br />
              Стоимость в месяц: ~{{ calcTpDay * 30 }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn outlined @click="dialogTp = false">Закрыть</v-btn>&nbsp;
          <v-btn color="primary" @click="saveTp">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <form
      style="display: none"
      method="POST"
      id="yandexPay"
      action="https://money.yandex.ru/quickpay/confirm.xml"
      target="_blank"
    >
      <input type="hidden" name="receiver" value="41001263811877" />
      <input type="hidden" name="formcomment" v-bind:value="paycomment" />
      <input
        type="hidden"
        name="short-dest"
        value="Оплата за Мастер на все руки"
      />
      <input type="hidden" name="writable-targets" value="false" />
      <input type="hidden" name="comment-needed" value="true" />
      <input type="hidden" name="quickpay-form" value="shop" />
      <input type="hidden" name="label" v-bind:value="paylabel" />
      <input type="hidden" name="targets" v-bind:value="paycomment" />
      <input
        type="hidden"
        name="sum"
        v-bind:value="cost_total"
        data-type="number"
      />
      <input type="radio" name="paymentType" value="PC" checked />
      <input
        type="submit"
        name="submit-button"
        value="Купить за Яндекс.Деньги"
      />
    </form>
    <form
      style="display: none"
      method="POST"
      id="visaPay"
      action="https://money.yandex.ru/quickpay/confirm.xml"
      target="_blank"
    >
      <input type="hidden" name="receiver" value="41001263811877" />
      <input type="hidden" name="formcomment" v-bind:value="paycomment" />
      <input
        type="hidden"
        name="short-dest"
        value="Оплата за Мастер на все руки"
      />
      <input type="hidden" name="writable-targets" value="false" />
      <input type="hidden" name="comment-needed" value="true" />
      <input type="hidden" name="quickpay-form" value="shop" />
      <input type="hidden" name="label" v-bind:value="paylabel" />
      <input type="hidden" name="targets" v-bind:value="paycomment" />
      <input
        type="hidden"
        name="sum"
        v-bind:value="cost_total"
        data-type="number"
      />
      <input type="radio" name="paymentType" value="AC" checked />
      <input
        type="submit"
        name="submit-button"
        value="Купить за Яндекс.Деньги"
      />
    </form>
    <form
      method="POST"
      style="display: none"
      id="paypal"
      target="paypal"
      action="https://www.paypal.com/cgi-bin/webscr"
    >
      <input type="hidden" name="cmd" value="_xclick" />
      <input type="hidden" name="business" value="support@beauty-saas.ru" />
      <input TYPE="hidden" name="charset" value="utf-8" />
      <input TYPE="hidden" NAME="return" value="http://t.info.domen/pay/" />
      <input type="hidden" name="amount" v-bind:value="cost_total" />
      <input type="hidden" name="currency_code" value="RUB" />
      <input type="hidden" name="custom" v-bind:value="paycomment" />
      <input type="hidden" name="no_shipping" value="1" />
      <input
        type="hidden"
        name="notify_url"
        value="http://' + t.info.domen + '/login/PaypalPaymentAccepted/"
      />
      <input type="hidden" name="item_name" v-bind:value="paycomment" />
      <input
        type="image"
        name="submit"
        border="0"
        src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif"
        alt="PayPal - The safer, easier way to pay online"
      />
    </form>
  </div>
</template>

<script>
//TODO: после переезда на нормальный домен сделать оплату по счету

export default {
  data() {
    return {
      info: [],
      referrer: [],
      dialog: false,
      modal_pay: '',
      modalView: '',
      basesmm: 0,
      sms: 0,
      selperiod: [],
      paytpe: [
        { value: 'visa', name: 'Банковской картой' },
        { value: 'yandex', name: 'Яндекс деньги' },
        { value: 'paypal', name: 'Paypal' },
        { value: 'bn', name: 'Выставить счет для организации' },
      ],
      selpaytpe: [],
      dialogTp: false,
      listtp: [],
      seltp: false,
      limittp: 0,
      flgLoad: false,
    }
  },
  components: {},
  computed: {
    period: function () {
      return [
        { name: '-нет-', value: 0, cost: 0 },
        { name: '1 месяц', value: 1, cost: this.info.cost * 30 },
        { name: '3 месяца', value: 3, cost: this.info.cost * 90 },
        { name: '6 месяцев', value: 6, cost: this.info.cost * 180 },
        { name: '12 месяцев', value: 12, cost: this.info.cost * 365 },
      ]
    },
    cost_total: function () {
      return this.sms + this.basesmm
    },
    paylabel: function () {
      return this.info.org + ';' + this.basesmm + ';' + this.sms
    },
    paycomment: function () {
      return (
        'Мастер на все руки. Оплата работы с программой сумма ' +
        this.basesmm +
        'р.  оплата за СМС ' +
        this.sms +
        'р. код организации:' +
        this.info.org
      )
    },
    calcTpDay: function () {
      var t = this
      return (
        parseFloat(t.limittp * t.seltp.cost_worker) + parseFloat(t.seltp.cost)
      )
    },
  },
  mounted: function () {
    this.dataLoad()
  },
  methods: {
    modalClose: function (prm) {
      this.modal_pay = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    changeTp: function () {
      var t = this
      t.seltp = t.listtp.filter((e) => e.id == t.info.tariff)[0]
      t.limittp = t.info.stataddlimit
      t.dialogTp = true
    },
    dataLoad: function () {
      var t = this
      t.$store.commit('loadingStartShow', true)
      t.flgLoad = true
      this.$http.post(this.$store.state.apiUrl + 'billing').then(
        (response) => {
          t.$store.commit('loadingStartShow', false)
          t.flgLoad = false
          if (response.body.err > 0) {
            t.info = ['error']
          } else {
            t.info = response.body
            t.listtp = response.body.tp
            t.seltp = t.listtp.filter((e) => e.id == t.info.tariff)[0]
            t.limittp = t.info.stataddlimit
            // console.log('🚀 ~ file: billing.vue ~ line 341 ~ t.seltp', t.seltp)
            t.basesmm = this.info.cost * 90
            t.selperiod = {
              name: '3 месяца',
              value: 3,
              cost: this.info.cost * 3,
            }
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    saveTp: function () {
      var t = this
      t.$store.commit('loadingStartShow', true)
      this.$http
        .post(this.$store.state.apiUrl + 'billing/saveTp/', {
          worker: this.limittp,
          tp: this.seltp.id,
        })
        .then(
          (response) => {
            t.$store.commit('loadingStartShow', false)
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              t.dialogTp = false
              t.dataLoad()
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
    save: function () {
      if (this.selpaytpe.value == 'yandex') {
        document.getElementById('yandexPay').submit()
      }
      if (this.selpaytpe.value == 'visa') {
        document.getElementById('visaPay').submit()
      }
      if (this.selpaytpe.value == 'paypal') {
        document.getElementById('paypal').submit()
      }
      if (this.selpaytpe.value == 'bn') {
        window.open(
          this.$store.state.apiUrl +
            'pdf/bill/?sms=' +
            this.basesmm +
            '&cost=' +
            this.sms,
          'report',
          ''
        )
      }
    },
  },
}
</script>

<style></style>
